import React, { useState } from "react";
import { Button, TextField, Typography, Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const SignUpPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { language } = useLanguage();

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post("/register", {
        username: username,
        email: email,
        password: password,
      });
      console.log(response.data);
      // Navigate to login page on successful registration
      navigate("/");
    } catch (error) {
      console.error(
        "There was an error registering the user",
        error.response.data
      );
      alert("Error registering the user");
    }
  };

  return (
    <div
      style={{
        direction: language === "arabic" ? "rtl" : "ltr",
        textAlign: language === "arabic" ? "right" : "left",
      }}
    >
      <Paper
        elevation={3}
        style={{ padding: 20, maxWidth: 400, margin: "20px auto" }}
      >
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          {language === "english" ? "Sign Up" : " تسجيل حساب"}
        </Typography>
        <form onSubmit={handleSignUp}>
          <TextField
            placeholder={language === "english" ? "email" : "اسم المستخدم"}
            fullWidth
            style={{ marginBottom: 20 }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            placeholder={
              language === "english" ? "email" : "البريد الالكتروني "
            }
            fullWidth
            style={{ marginBottom: 20 }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            placeholder={language === "english" ? "password" : "كلمة السر "}
            type="password"
            fullWidth
            style={{ marginBottom: 20 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            placeholder={
              language === "english" ? "confirm password" : " تاكيد كلمة السر "
            }
            type="password"
            fullWidth
            style={{ marginBottom: 20 }}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              bgcolor: "#c504ec",
              "&:hover": {
                bgcolor: "#c504ec", // Keep the same color on hover
              },
            }}
          >
            {language === "english" ? "Sign Up" : " تسجيل حساب"}
          </Button>
        </form>
        <Typography style={{ marginTop: 20 }}>
          {language === "english"
            ? " Already have an account?"
            : "  لديك حساب ؟"}
          <Link to="/login">
            {" "}
            {language === "english" ? "Login" : "تسجيل الدخول "}
          </Link>
        </Typography>
      </Paper>
    </div>
  );
};

export default SignUpPage;
