import React, { useState } from "react";
import { Button, TextField, Typography, Paper } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const LoginPage = ({ setLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [error, setError] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASIC_URL}login`,
        {
          email,
          password,
        }
      );

      const token = response.data.token;
      localStorage.setItem("token", token);
      setLoggedIn(true);
      navigate("/dashboard");
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || "Unknown error");
      } else if (error.request) {
        setError("No response received from server");
      } else {
        setError("Error occurred while processing the request");
      }
    }
  };

  return (
    <>
      <div
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
          textAlign: language === "arabic" ? "right" : "left",
        }}
      >
        <Paper
          elevation={3}
          style={{ padding: 20, maxWidth: 400, margin: "100px auto" }}
        >
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {language === "english" ? "Login" : "تسجيل الدخول "}
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              placeholder={language === "english" ? "email" : "اسم المستخدم"}
              fullWidth
              style={{ marginBottom: 20 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              placeholder={language === "english" ? "password" : "كلمة السر "}
              type="password"
              fullWidth
              style={{ marginBottom: 20 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <Typography style={{ color: "red" }}>{error}</Typography>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                bgcolor: "#c504ec",
                "&:hover": {
                  bgcolor: "#c504ec",
                },
              }}
            >
              {language === "english" ? "Login" : "تسجيل الدخول "}
            </Button>
          </form>
          <Typography style={{ marginTop: 20 }}>
            {language === "english"
              ? "Don't have an account?"
              : "ليس لديك حساب؟"}{" "}
            <Link to="/signup">
              {language === "english" ? "Sign Up" : "سجل"}
            </Link>{" "}
          </Typography>
        </Paper>
      </div>
    </>
  );
};

export default LoginPage;
