import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#F8F8F8",
        color: "#000",
        py: 5,
        textAlign: "center",
      }}
    >
      <Container>
        <Typography variant="body2">
          &copy; {currentYear} YaiGlobal. All rights reserved.
        </Typography>
        <Typography variant="body2">
          <Link href="https://yaiglobal.com" color="#ce04f6" underline="hover">
            Visit YaiGlobal.com
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
