import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  CloudUpload as CloudUploadIcon,
  ArrowBackIos,
  ArrowForwardIos,
  ContentCopy as ContentCopyIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import SwipeableViews from "react-swipeable-views";
import { useLanguage } from "./LanguageContext";

const OCRComponent = () => {
  const [file, setFile] = useState(null);
  const [extractedPages, setExtractedPages] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const { language } = useLanguage();
  const [activeStep, setActiveStep] = useState(0);
  const [previousOCRTexts, setPreviousOCRTexts] = useState([]);
  const [selectedOCR, setSelectedOCR] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchPreviousOCRTexts();
  }, []);

  const fetchPreviousOCRTexts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_URL}previous_ocr`
      );
      setPreviousOCRTexts(response.data.ocr_results || []);
    } catch (error) {
      console.error("Error fetching previous OCR texts:", error);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const steps = [
    language === "english"
      ? "Select a PDF file"
      : "اختر ملف PDF",
    language === "english" ? "Wait for PDF processing" : "انتظر معالجة ملف PDF",
    language === "english" ? "Copy extracted text" : "نسخ النص المستخرج",
  ];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/pdf",
    disabled: isLoading || !!file,
  });

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file.");
      return;
    }

    setError(null);
    setIsLoading(true);
    setActiveStep(1);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASIC_URL}ocr`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setExtractedPages(response.data.pages);
      setCurrentPageIndex(0);
      setActiveStep(2);
      fetchPreviousOCRTexts();
    } catch (error) {
      setError("Error processing the file.");
      console.error("Error:", error);
    }
    setIsLoading(false);
  };

  const handleChangeIndex = (index) => {
    setCurrentPageIndex(index);
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const handleRemoveFile = () => {
    setFile(null);
    setError(null);
  };

  const handleOpenModal = (ocr) => {
    setSelectedOCR(ocr);
  };

  const handleCloseModal = () => {
    setSelectedOCR(null);
  };

  const handleExportJson = (ocr) => {
    const formattedOCR = {
      filename: ocr.filename,
      pages: ocr.pages.map((page) => ({
        page_number: page.page_num,
        text: page.text,
      })),
    };
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(formattedOCR, null, 2)], {
      type: "application/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = `ocr_${ocr.id}.json`;
    document.body.appendChild(element);
    element.click();
  };

  const handleDelete = async (ocrId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASIC_URL}ocr/${ocrId}`);
      fetchPreviousOCRTexts();
    } catch (error) {
      console.error("Error deleting OCR:", error);
    }
  };

  return (
    <div
      style={{
        direction: language === "arabic" ? "rtl" : "ltr",
        textAlign: language === "arabic" ? "right" : "left",
      }}
    >
      <Container maxWidth="" sx={{ mt: 2 }}>
        <Typography
          display="flex"
          variant="h5"
          alignItems="left"
          sx={{ mt: -9, ml: 1, color: "#e3a40c", fontWeight: "bold" }}
        >
          {language === "english" ? "OCR" : "استخراج النص"}
        </Typography>
        <Box
          sx={{
            mb: 3,
            width: "90%",
            mt: 3,
            ml: isSmallScreen ? 0 : 2,
            px: isSmallScreen ? 2 : 0,
          }}
        >
          <Stepper
            activeStep={activeStep}
            display="flex"
            justifyContent="center"
            orientation={isSmallScreen ? "vertical" : "horizontal"}
            sx={{
              mr: language === "arabic" ? "40px" : "0px",
              ml: language === "arabic" ? "0px" : "40px",
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems="flex-start"
          sx={{ gap: 4, justifyContent: "center" }}
        >
          <Box
            {...getRootProps()}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: isSmallScreen ? "100%" : "60%",
              p: 3,
              bgcolor: "#f5f5f5",
              borderRadius: 2,
              border: "2px dashed #c504ec",
              cursor: "pointer",
              transition: "background-color 0.3s",
              "&:hover": {
                bgcolor: isLoading || !!file ? "#f5f5f5" : "#e3e3e3",
              },
            }}
          >
            <input {...getInputProps()} disabled={isLoading || !!file} />
            {isDragActive ? (
              <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
                {language === "english"
                  ? "Drop the file here ..."
                  : "إسقاط الملف هنا ..."}
              </Typography>
            ) : (
              <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
                {language === "english"
                  ? "Drag & drop a PDF file here, or click to select a file"
                  : "قم بسحب وإسقاط ملف PDF هنا"}
              </Typography>
            )}
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              {file && (
                <>
                  <PictureAsPdfIcon fontSize="large" color="gray" />
                  <IconButton onClick={handleRemoveFile} sx={{ ml: 1 }}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </>
              )}
            </Box>

            {isLoading && <CircularProgress size={24} sx={{ mt: 2 }} />}
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                {error}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              justifyItems: isSmallScreen ? "100%" : "30%",
              mt: isSmallScreen ? 2 : 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: language === "arabic" ? "30%" : "30%",
            }}
          >
            <Button
              onClick={handleUpload}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              disabled={!file || isLoading}
            >
              {language === "english" ? "Extract text" : "استخرج النص"}
            </Button>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ width: "100%", mt: 4 }}
        >
          {extractedPages.length > 0 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "800px",
                  mt: -1,
                  direction: "ltr",
                }}
              >
                <IconButton
                  onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
                  disabled={currentPageIndex === 0}
                >
                  <ArrowBackIos />
                </IconButton>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {`${currentPageIndex + 1} / ${extractedPages.length}`}
                </Typography>
                <IconButton
                  onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
                  disabled={currentPageIndex === extractedPages.length - 1}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Box>
              <Paper
                elevation={4}
                sx={{
                  p: 0,
                  width: isSmallScreen ? "90%" : "60%",
                  height: "100vh",
                  overflowY: "auto",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.3)",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                  position: "relative",
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <Button
                    startIcon={<ContentCopyIcon />}
                    onClick={() =>
                      handleCopyText(extractedPages[currentPageIndex])
                    }
                    sx={{ mb: 2, fontSize: 10 }}
                  >
                    {copied
                      ? language === "english"
                        ? "Copied"
                        : "تم النسخ"
                      : language === "english"
                      ? "Copy Text"
                      : "نسخ النص"}
                  </Button>
                </Box>
                <SwipeableViews
                  index={currentPageIndex}
                  onChangeIndex={handleChangeIndex}
                >
                  {extractedPages.map((page, index) => (
                    <Box
                      key={index}
                      sx={{
                        p: 6,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: "pre-line",
                          textAlign: [
                            language === "arabic" ? "right" : "left",
                          ],
                          fontFamily: "'Times New Roman', Times, serif",
                          lineHeight: 1.8,
                          mr: 5,
                        }}
                      >
                        {page}
                      </Typography>
                    </Box>
                  ))}
                </SwipeableViews>
              </Paper>
            </>
          ) : (
            <Paper
              elevation={4}
              sx={{
                p: 4,
                width: isSmallScreen ? "90%" : "70%",
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0 8px 16px rgba(0,0,0,0.3)",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                mt: -2,
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "#aaa" }}
              >
                {language === "english"
                  ? "No content to display. Please upload a PDF to start the OCR process."
                  : "لا يوجد محتوى للعرض. يرجى تحميل ملف PDF لبدء عملية OCR."}
              </Typography>
            </Paper>
          )}
        </Box>

        <Typography
          variant="h6"
          sx={{ color: "#e3a40c", fontWeight: "bold", mb: 1, mt: 3 }}
        >
          {language === "english" ? "Previous OCR Texts" : "نصوص OCR السابقة"}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {previousOCRTexts.length > 0 &&
            previousOCRTexts.map((ocr, index) => (
              <Paper
                key={index}
                elevation={2}
                sx={{
                  p: 2,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  transition: "background-color 0.3s",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
                onClick={() => handleOpenModal(ocr)}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#333" }}
                  >
                    {ocr.filename.substring(0, 50)}...
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {ocr.filename}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{
                      bgcolor: "#c504ec",
                      opacity: "70%",
                      "&:hover": { backgroundColor: "#9b03db" },
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenModal(ocr);
                    }}
                  >
                    {language === "english" ? "View" : "عرض"}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    sx={{
                      bgcolor: "#c504ec",
                      opacity: "70%",
                      "&:hover": { backgroundColor: "#9b03db" },
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExportJson(ocr);
                    }}
                  >
                    {language === "english"
                      ? "Export as JSON"
                      : "تصدير كملف JSON"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(ocr.id);
                    }}
                  >
                    {language === "english" ? "Delete" : "حذف"}
                </Button>
                </Box>
              </Paper>
            ))}
        </Box>

        <Dialog open={Boolean(selectedOCR)} onClose={handleCloseModal}>
          <DialogTitle>
            {language === "english" ? "OCR Details" : "تفاصيل OCR"}
          </DialogTitle>
          {selectedOCR && (
            <DialogContent>
              <DialogContentText>
                <strong>
                  {language === "english" ? "Filename" : "اسم الملف"}:
                </strong>
                <br />
                {selectedOCR.filename}
              </DialogContentText>
              <DialogContentText>
                <strong>
                  {language === "english" ? "Extracted Text" : "النص المستخرج"}:
                </strong>
                <br />
                {selectedOCR.pages.map((page, index) => (
                  <React.Fragment key={index}>
                    <strong>{`Page ${page.page_num}:`}</strong> {page.text}
                    <br />
                  </React.Fragment>
                ))}
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              {language === "english" ? "Close" : "إغلاق"}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default OCRComponent;
