import React from "react";
import { ButtonGroup, Button } from "@mui/material";
import { useLanguage } from "./LanguageContext";

const LanguageSwitch = () => {
  const { language, toggleLanguage } = useLanguage();

  return (
    <ButtonGroup variant="text" sx={{ ml: 6 }}>
      <Button
        disabled={language === "english"} // Disable if already in English
        sx={{ color: language === "english" ? "#c504ec" : "grey" }}
        onClick={() => toggleLanguage("english")}
      >
        EN
      </Button>
      <Button
        disabled={language === "arabic"} // Disable if already in Arabic
        sx={{ color: language === "arabic" ? "#c504ec" : "grey" }}
        onClick={() => toggleLanguage("arabic")}
      >
        AR
      </Button>
    </ButtonGroup>
  );
};

export default LanguageSwitch;
