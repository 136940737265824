import React from "react";
import { Link } from "react-router-dom";
import { Row, Container } from "react-bootstrap";
import bgpic from "./images/background.png";
import { useLanguage } from "../LanguageContext";

function HeroBanner({ loggedIn }) {
  const { language } = useLanguage();

  // Define inline styles for section-content based on language
  const sectionContentStyles = {
    marginBottom: "70px",
    marginInline: "40px",
    marginTop: "120px",
    position: "relative",
    zIndex: 1,
    // Conditional styles based on language
    ...(language === "arabic"
      ? { marginRight: "100px", marginLeft: "auto" }
      : { marginRight: "auto", marginLeft: "100px" }),
  };

  return (
    <section
      id="home"
      dir={language === "arabic" ? "rtl" : "ltr"}
      style={{ position: "relative" }}
    >
      {/* Background image */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${bgpic})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: 1,
          borderRadius: "10px",
        }}
      />

      <Container style={sectionContentStyles}>
        <Row>
          <div className="section-content">
            <div>
              <div className="section-text__subtitle">
                {language === "english"
                  ? "Welcome to  Yai4Ottoman"
                  : "مرحبا بكم في منصة ياي للمخطوطات العثمانية"}
              </div>
              <div className="section-text__title-big">
                {language === "english"
                  ? "Explore the Legacy of the Ottoman Empire"
                  : "استكشف إرث الإمبراطورية العثمانية"}
              </div>
              <div className="section-text__body">
                {language === "english"
                  ? " Exploring Ottoman history on our platform, powered by AI for translating and summarizing manuscripts, and more."
                  : "استكشف تاريخ الدولة العثمانية على منصتنا، مدعومًا بالذكاء الاصطناعي لترجمة وتلخيص المخطوطات، والمزيد."}
              </div>
              <Link to="/dashboard" className="download-btn">
                {language === "english" ? "Explore Now" : "اكتشف الآن"}
              </Link>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default HeroBanner;
