import React from "react";
import "./styles.css";

import HeroBanner from "./HeroBanner";
import Features from "./Features";
import Services from "./Services";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "aos/dist/aos.css";
import Aos from "aos";

export default function HomePage() {
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("Token");

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleEnterDashboard = () => {
    if (loggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <main height>
        <HeroBanner />
        <Services />
        <Features />
      </main>
    </>
  );
}
