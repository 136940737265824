import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import axios from "axios";
import { useLanguage } from "./LanguageContext";
import { useDropzone } from "react-dropzone";

const BookmarkingComponent = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfInfo, setPdfInfo] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [error, setError] = useState(null);
  const { language } = useLanguage();

  const fetchPdfInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_URL}all_pdfs`
      );
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      setPdfInfo(response.data || []);
    } catch (error) {
      console.error("Error fetching PDF info:", error);
      alert("Failed to fetch PDF info.");
    }
  };

  useEffect(() => {
    fetchPdfInfo();
  }, []);

  const handleFileChange = async (file) => {
    setSelectedFile(file);
    await uploadFile(file);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("pdf_file", file);
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASIC_URL}bookmark`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("File uploaded successfully!");
      fetchPdfInfo();
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file.");
    }
    setLoading(false);
  };

  const handleMenuOpen = (event, pdf) => {
    setAnchorEl(event.currentTarget);
    setSelectedPdf(pdf);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPdf(null);
  };

  const handleDownload = async (filename, type) => {
    try {
      const downloadUrl = `${process.env.REACT_APP_BASIC_URL}${type}/${filename}`;
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error downloading file:", error);
      alert(`Failed to download file: ${error.message}`);
    }
  };

  const handleDelete = async (pdf_id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASIC_URL}delete_pdf/${pdf_id}`
      );
      alert("File deleted successfully!");
      fetchPdfInfo();
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileChange(acceptedFiles[0]),
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{ maxWidth: "100%", p: 2, mt: -10 }}
    >
      <Typography
        variant="h5"
        sx={{ mt: 1, mb: 2, color: "#e3a40c", fontWeight: "bold" }}
      >
        {language === "english" ? "Bookmarking" : "الإشارات المرجعية"}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box
          {...getRootProps()}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
            maxWidth: "600px",
            p: 3,
            bgcolor: "#f5f5f5",
            borderRadius: 2,
            border: "2px dashed #c504ec",
            cursor: "pointer",
            transition: "background-color 0.3s",
            position: "relative",
            "&:hover": {
              bgcolor: "#e3e3e3",
            },
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
              {language === "english"
                ? "Drop the file here ..."
                : "إسقاط الملف هنا ..."}
            </Typography>
          ) : (
            <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
              {language === "english"
                ? "Click or Drag & drop a PDF file here"
                : "قم بسحب وإسقاط ملف PDF هنا"}
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            {loading && <CircularProgress size={24} sx={{ mt: 2 }} />}
            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                {error}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ mt: 3, maxHeight: 400, overflowY: "auto" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                }}
              >
                {language === "english" ? "Filename" : "اسم الملف"}
              </TableCell>
              <TableCell
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                }}
              >
                {language === "english" ? "Page Number" : "عدد الصفحات"}
              </TableCell>
              <TableCell
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                }}
              >
                {language === "english" ? "Size" : "الحجم"}
              </TableCell>
              <TableCell
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                }}
              >
                {language === "english" ? "Status" : "الحالة"}
              </TableCell>
              <TableCell
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                }}
              >
                {language === "english" ? "Actions" : "إجراءات"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pdfInfo && pdfInfo.length > 0 ? (
              pdfInfo
                .slice()
                .reverse()
                .map((pdf) => (
                  <TableRow key={pdf.filename}>
                    <TableCell>{pdf.filename}</TableCell>
                    <TableCell>{pdf.page_num}</TableCell>
                    <TableCell>{pdf.size}</TableCell>
                    <TableCell>{pdf.status}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(event) => handleMenuOpen(event, pdf)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedPdf === pdf}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={() =>
                            handleDownload(pdf.filename, "original")
                          }
                        >
                          {language === "english"
                            ? "Open Original PDF"
                            : "افتح ملف PDF الأصلي"}
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleDownload(pdf.filename, "output")}
                        >
                          {language === "english"
                            ? "Open Processed PDF"
                            : "افتح ملف PDF المعالج"}
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(pdf.id)}>
                          {language === "english"
                            ? "Delete PDF"
                            : "حذف ملف PDF"}
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {language === "english"
                    ? "No PDFs available"
                    : "لا توجد ملفات PDF متاحة"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BookmarkingComponent;
