import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

const UserButton = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token") != null;

  const handleLogin = useCallback(() => {
    // Navigate to login page or perform login logic
    navigate("/login");
  }, [navigate]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    navigate("/");
  }, [navigate, setLoggedIn]);

  return (
    <Button
      startIcon={isAuthenticated ? <LogoutIcon /> : <AccountCircleIcon />}
      onClick={isAuthenticated ? handleLogout : handleLogin}
      color="inherit"
      sx={{
        mr: 6,
        ml: 1,
        backgroundColor: "#c504ec",
        width: "110px",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center", // Center align icon and text vertically
      }}
    >
      <span className="icon-text">{isAuthenticated ? "Logout" : "Login"}</span>
      <style jsx>{`
        @media screen and (max-width: 768px) {
          .icon-text {
            display: none;
          }
        }
      `}</style>
    </Button>
  );
};

export default UserButton;
