import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  IconButton,
} from "@mui/material";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import TranslationComponent from "./TranslationComponent";
import TextSummarisationComponent from "./TextSummarisationComponent";
import NamedEntityRecognitionComponent from "./NamedEntityRecognitionComponent";
import BookmarkingComponent from "./BookmarkingComponent";
import OCRComponent from "./OCRComponent";
import TranslateIcon from "@mui/icons-material/Translate";
import ShortTextIcon from "@mui/icons-material/ShortText";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import MenuIcon from "@mui/icons-material/Menu";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { useLanguage } from "./LanguageContext";

const services = [
  {
    name: "Arabic OCR",
    translations: {
      english: "Text extraction OCR",
      arabic: "إستخراج النص OCR",
    },
    path: "ocr",
    Component: OCRComponent,
    Icon: TextSnippetOutlinedIcon,
  },
  {
    name: "Translation",
    translations: { english: "Translation", arabic: "ترجمة" },
    path: "translation",
    Component: TranslationComponent,
    Icon: TranslateIcon,
  },
  {
    name: "Text Summarisation",
    translations: { english: "Text Summarisation", arabic: "تلخيص النص" },
    path: "text-summarisation",
    Component: TextSummarisationComponent,
    Icon: ShortTextIcon,
  },
  {
    name: "Named Entity Recognition",
    translations: {
      english: "Named Entity Recognition",
      arabic: "الكيانات المسماة",
    },
    path: "named-entity-recognition",
    Component: NamedEntityRecognitionComponent,
    Icon: FormatIndentIncreaseIcon,
  },
  {
    name: "Bookmarking",
    translations: { english: "Bookmarking", arabic: "الإشارات المرجعية" },
    path: "bookmarking",
    Component: BookmarkingComponent,
    Icon: BookmarkBorderIcon,
  },
];

const Dashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const { language } = useLanguage();
  const languageKey = language ? language.toLowerCase() : "english";
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      navigate("/dashboard/ocr");
    }
  }, [location, navigate]);

  const handleServiceChange = (service) => {
    navigate(`/dashboard/${service.path}`);
    setIsDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div
      style={{
        direction: language === "arabic" ? "rtl" : "ltr",
        textAlign: language === "arabic" ? "right" : "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          minHeight: "100vh",
          pt: theme.spacing(7),
        }}
      >
        <Drawer
          anchor={languageKey === "arabic" ? "right" : "left"}
          open={isDrawerOpen}
          onClose={toggleDrawer}
        >
          <List>
            {services.map((service) => (
              <ListItem
                button
                key={service.name}
                onClick={() => handleServiceChange(service)}
                sx={{
                  direction: languageKey === "arabic" ? "rtl" : "ltr",
                  textAlign: languageKey === "arabic" ? "right" : "left",
                }}
              >
                <ListItemIcon>
                  <service.Icon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    service.translations[languageKey] || "Missing Translation"
                  }
                />
              </ListItem>
            ))}
          </List>
        </Drawer>

        <IconButton
          onClick={toggleDrawer}
          sx={{
            display: { xs: "block", sm: "none" },
            position: "fixed",
            mt: -6,
            mr: -2,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Box
          sx={{
            flex: "0 0 auto",
            width: "200px",
            backgroundColor: "#F8F8F8",
            height: "210vh",
            mt: "-74px",
            pt: "24px",
            marginBottom: -10,
            marginLeft: languageKey === "english" ? -3 : "auto",
            marginRight: languageKey === "arabic" ? -3 : "auto",
            boxSizing: "border-box",
            display: { xs: "none", sm: "block" },
            borderRight:
              languageKey === "english"
                ? "1px solid rgba(0, 0, 0, 0.12)"
                : "none",
            borderLeft:
              languageKey === "arabic"
                ? "1px solid rgba(0, 0, 0, 0.12)"
                : "none",
          }}
        >
          <List>
            {services.map((service) => (
              <ListItem
                button
                key={service.name}
                onClick={() => handleServiceChange(service)}
                sx={{
                  backgroundColor: "#F8F8F8",
                  direction: languageKey === "arabic" ? "rtl" : "ltr",
                  textAlign: languageKey === "arabic" ? "right" : "left",
                }}
              >
                <ListItemIcon>
                  <service.Icon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    service.translations[languageKey] || "Missing Translation"
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            {services.map((service) => (
              <Route
                key={service.path}
                path={service.path}
                element={<service.Component />}
              />
            ))}
            {/* Handle redirect if no sub-path is present */}
            <Route
              path="*"
              element={<Navigate to="/dashboard/ocr" />}
            />
          </Routes>
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
