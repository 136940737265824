import React, { useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Collapse,
  Tab,
  Slider,
} from "@mui/material";
import axios from "axios";
import { useLanguage } from "./LanguageContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GetAppIcon from "@mui/icons-material/GetApp";

const TextSummarisationComponent = () => {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [summarizationMode, setSummarizationMode] = useState("paragraph");
  const [customPrompt, setCustomPrompt] = useState("");
  const [summaryLengthIndex, setSummaryLengthIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [Language, setLanguage] = useState("Arabic");
  const [previousSummarizations, setPreviousSummarizations] = useState([]);
  const [selectedSummarization, setSelectedSummarization] = useState(null);
  const { language } = useLanguage();

  useEffect(() => {
    fetchPreviousSummarizations();
  }, []);

  const fetchPreviousSummarizations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_URL}summarizations`
      );
      setPreviousSummarizations(response.data.summarizations);
    } catch (error) {
      console.error("Error fetching previous summarizations:", error);
    }
  };

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setSummarizationMode(newMode);
    }
  };

  const handleSliderChange = (_, newValue) => {
    setSummaryLengthIndex(newValue);
  };

  const getSliderLabel = () => {
    return (
      marks.find((mark) => mark.value === summaryLengthIndex)?.label ||
      "default"
    );
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleErrorClose = () => setIsErrorOpen(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    setOutputText(""); // Clear previous output
    setError(""); // Clear previous errors
    setIsErrorOpen(false); // Close error dialog if it was open

    const requestData = {
      text: inputText,
      mode: summarizationMode,
      prompt: customPrompt,
      length: getSliderLabel().toLowerCase(),
      language: Language,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASIC_URL}summarization`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Error: ${response.statusText}`);
      }

      const data = await response.json();
      setOutputText(data.summary);
      fetchPreviousSummarizations(); // Refresh previous summarizations after new summarization
    } catch (error) {
      console.error("Error submitting summarization request:", error.message);
      setError(error.message);
      setIsErrorOpen(true);
    }

    setIsLoading(false);
  };

  const countWords = (text) => {
    return text.split(/\s+/).filter(Boolean).length;
  };

  const countSentences = (text) => {
    return text.split(/[\.\?\!]+/).filter(Boolean).length;
  };

  const handleOpenModal = (summarization) => {
    setSelectedSummarization(summarization);
  };

  const handleCloseModal = () => {
    setSelectedSummarization(null);
  };

  const handleDelete = async (summarizationId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASIC_URL}summarizations/${summarizationId}`);
      fetchPreviousSummarizations(); // Refresh previous translations after deletion
    } catch (error) {
      console.error("Error deleting summarization:", error);
    }
  };

  const handleExportJson = (summarization) => {
    const { id, ...data } = summarization;

    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "summarization.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const marks = [
    { value: 0, label: language === "english" ? "Brief" : "موجز" },
    { value: 1, label: language === "english" ? "Short" : "قصير" },
    { value: 2, label: language === "english" ? "Medium" : "متوسط" },
    { value: 3, label: language === "english" ? "Long" : "طويل" },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{ maxWidth: "100%", p: 0.5, mt: -8 }}
    >
      <Typography
        variant="h5"
        sx={{ mb: 8, color: "#e3a40c", fontWeight: "bold" }}
      >
        {language === "english" ? "Summarisation" : "تلخيص النص"}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{ maxWidth: "100%", p: 0.5, mt: -8 }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={summarizationMode}
                onChange={handleModeChange}
                aria-label="Summarization Mode"
              >
                <Tab
                  value="paragraph"
                  label={language === "english" ? "Paragraph" : "فقرة"}
                />
                <Tab
                  value="bullets"
                  label={
                    language === "english" ? "Bullet Points" : "رؤوس أقلام"
                  }
                />
                <Tab
                  value="advanced"
                  label={language === "english" ? "Advanced" : "متقدم"}
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={summarizationMode === "paragraph"}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography
                    variant="h10"
                    sx={{ color: "primary", mr: 2, fontSize: 14 }}
                  >
                    {language === "english" ? "Summary Length:" : "طول الملخص:"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Slider
                    value={summaryLengthIndex}
                    onChange={handleSliderChange}
                    aria-labelledby="summary-length-slider"
                    valueLabelDisplay="off"
                    marks={marks}
                    min={0}
                    max={3}
                    sx={{
                      width: 200,
                      color: "#c504ec",
                      mt: 3,
                    }}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={summarizationMode === "advanced"}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <TextField
                    placeholder={
                      language === "english" ? "Custom Prompt" : "مؤشر مخصص"
                    }
                    variant="outlined"
                    size="small"
                    value={customPrompt}
                    onChange={(event) => setCustomPrompt(event.target.value)}
                    sx={{ width: 500 }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <TextField
              placeholder={language === "english" ? "Enter text " : "أدخل النص"}
              multiline
              rows={8}
              variant="outlined"
              fullWidth
              value={inputText}
              onChange={handleTextChange}
              sx={{ mb: 1 }}
            />
            <Typography
              variant="caption"
              display="block"
              sx={{ textAlign: "left", color: "#c504ec" }}
            >
              {language === "english" ? "words" : "كلمة"}:{" "}
              {countWords(inputText)} |
              {language === "english" ? "Sentences" : "جملة"}:{" "}
              {countSentences(inputText)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="language-select-label">
                {language === "english"
                  ? "Target Language"
                  : "اللغة المنشودة"}
              </InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={Language}
                label="Language"
                onChange={handleLanguageChange}
              >
                <MenuItem value="Arabic">
                  {language === "english" ? "Arabic" : "عربية"}
                </MenuItem>
                <MenuItem value="English">
                  {language === "english" ? "English" : "إنجليزية"}
                </MenuItem>
                <MenuItem value="Turkish">
                  {language === "english" ? "Turkish" : "تركية"}
                </MenuItem>
                <MenuItem value="Turkish">
                  {language === "english" ? "French" : "فرنسية"}
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                bgcolor: "#c504ec",
                mt: 2,
                "&:hover": { backgroundColor: "#9b03db" },
              }}
              onClick={handleSubmit}
              disabled={!inputText}
            >
              {language === "english" ? "Summarize" : "تلخيص "}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} sx={{ p: 2, flexGrow: 1, mt: 2 }}>
        <TextField
          multiline
          rows={8}
          variant="outlined"
          fullWidth
          value={outputText}
          InputProps={{ readOnly: true }}
          helperText={isLoading ? <CircularProgress size={24} /> : ""}
        />
        <Typography
          variant="caption"
          display="block"
          sx={{ textAlign: "left", color: "#c504ec" }}
        >
          {language === "english" ? "words" : "كلمة"}: {countWords(outputText)}{" "}
          |{language === "english" ? "Sentences" : "جملة"}:{" "}
          {countSentences(outputText)}
        </Typography>
      </Paper>
      <Typography
        variant="h6"
        sx={{ color: "#e3a40c", fontWeight: "bold", mb: 1, mt: 3 }}
      >
        {language === "english"
          ? "Previous Summarizations"
          : "التلخيصات السابقة"}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {previousSummarizations.map((summarization, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              p: 2,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            onClick={() => handleOpenModal(summarization)}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {summarization.summarized_text.substring(0, 50)}...
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {summarization.target_language}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  bgcolor: "#c504ec",
                  opacity: "70%",
                  "&:hover": { backgroundColor: "#9b03db" },
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(summarization);
                }}
              >
                {language === "english" ? "View" : "عرض"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  bgcolor: "#c504ec",
                  opacity: "70%",
                  "&:hover": { backgroundColor: "#9b03db" },
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleExportJson(summarization);
                }}
              >
                {language === "english" ? "Export as JSON" : "تصدير كملف JSON"}
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(summarization.id);
                }}
              >
                {language === "english" ? "Delete" : "حذف"}
              </Button>
            </Box>
          </Paper>
        ))}
      </Box>

      <Dialog open={Boolean(selectedSummarization)} onClose={handleCloseModal}>
        <DialogTitle>
          {language === "english" ? "Summarization Details" : "تفاصيل التلخيص"}
        </DialogTitle>
        {selectedSummarization && (
          <DialogContent>
            <DialogContentText>
              <strong>
                {language === "english" ? "Original Text" : "النص الأصلي"}:
              </strong>
              <br />
              {selectedSummarization.original_text}
            </DialogContentText>
            <DialogContentText>
              <strong>
                {language === "english" ? "Summarized Text" : "النص الملخص"}:
              </strong>
              <br />
              {selectedSummarization.summarized_text}
            </DialogContentText>
            <DialogContentText>
              <strong>{language === "english" ? "Language" : "اللغة"}:</strong>
              <br />
              {selectedSummarization.target_language}
            </DialogContentText>
            <DialogContentText>
              <strong>{language === "english" ? "Mode" : "الوضع"}:</strong>
              <br />
              {selectedSummarization.summary_mode}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {language === "english" ? "Close" : "إغلاق"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TextSummarisationComponent;
