import React from "react";
import { Link } from "react-router-dom";
import TranslateIcon from "@mui/icons-material/Translate";
import ShortTextIcon from "@mui/icons-material/ShortText";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import { useLanguage } from "../LanguageContext";

function Services() {
  const { language } = useLanguage();
  const services = [
    {
      title: language === "english" ? "Text Extraction OCR" : "استخراج النصوص OCR",
      desc:
        language === "english"
          ? "Extract text from your documents with precision."
          : "قم باستخراج النصوص من مستنداتك بدقة.",
      icon: <TextSnippetOutlinedIcon  />,  
      route: "/dashboard/ocr",
    },
    {
      title: language === "english" ? "Translation" : "ترجمة",
      desc:
        language === "english"
          ? "Translate your documents accurately and efficiently."
          : "قم بترجمة مستنداتك بدقة وكفاءة.",
      icon: <TranslateIcon />,
      route: "/dashboard/translation",
    },
    {
      title: language === "english" ? "Summarization" : "تلخيص",
      desc:
        language === "english"
          ? "Get concise summaries of lengthy texts for quick understanding."
          : "احصل على ملخصات موجزة للنصوص الطويلة لفهم سريع.",
      icon: <ShortTextIcon />,
      route: "/dashboard/text-summarisation",
    },
    {
      title: language === "english" ? "NER" : "تسمية الكيانات",
      desc:
        language === "english"
          ? "Identify and classify named entities in your text data."
          : "تعرف على الكيانات المسماة وتصنيفها في بيانات النص الخاصة بك.",
      icon: <BookmarkBorderIcon />,
      route: "/dashboard/named-entity-recognition",
    },
    {
      title: language === "english" ? "Bookmarking" : "الإشارات المرجعية",
      desc:
        language === "english"
          ? "Bookmark important sections for easy access and reference."
          : "قم بوضع إشارات مرجعية للأقسام الهامة للوصول والاطلاع بسهولة.",
      icon: <FormatIndentIncreaseIcon />,
      route: "/dashboard/bookmarking",
    },
  ];

  return (
    <section id="services" style={{ marginBottom: "10px" }}>
      <div
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
          textAlign: language === "arabic" ? "right" : "left",
        }}
      ></div>
      <div className="services-container">
        <div className="section-text">
          <div className="section-text__title-centered">
            {language === "english" ? "Services" : "الخدمات"}
          </div>
          <div className="service-cards">
            {services.map((service, index) => (
              <div className="service-card" key={index}>
                <Link to={service.route} className="service-link">
                  <div className="service-card__icon">{service.icon}</div>
                </Link>
                <div className="service-card__text-container">
                  <div className="section-text__title-small">
                    {service.title}
                  </div>
                  <div className="service-desc">{service.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div />
    </section>
  );
}

export default Services;
