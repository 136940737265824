import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu";
import Logo from "../assets/logo.png";
import LanguageSwitch from "./LanguageSwitch";
import HomeIcon from "@mui/icons-material/Home"; // Import Home icon

const MyAppBar = ({ setLoggedIn, isAuthenticated }) => {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#F8F8F8", height: 90, mr: 20 }}
    >
      <Toolbar style={{ paddingTop: 20 }}>
        <img
          src={Logo}
          alt="Logo"
          style={{ marginLeft: 40, height: 60, marginRight: 40 }}
        />
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, color: "#ededed" }}
        ></Typography>
        <IconButton
          component={Link}
          to="/"
          color="#000"
          aria-label="home"
          style={{ marginRight: "-25px" }}
        >
          <HomeIcon />
        </IconButton>
        <LanguageSwitch />

        <UserMenu setLoggedIn={setLoggedIn} isAuthenticated={isAuthenticated} />
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
