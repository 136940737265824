import React, { useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from "@mui/material";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const NamedEntityRecognitionComponent = () => {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [previousNERs, setPreviousNERs] = useState([]);
  const [selectedNER, setSelectedNER] = useState(null);
  const { language } = useLanguage();

  useEffect(() => {
    fetchPreviousNERs();
  }, []);

  const fetchPreviousNERs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_URL}previous_ner`
      );
      setPreviousNERs(response.data.ners);
    } catch (error) {
      console.error("Error fetching previous NERs:", error);
    }
  };

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = { text: inputText };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASIC_URL}ner`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
      const cleanedText = inputText.replace(/['"]/g, '');
      setOutputText(cleanedText); // Keep the original input text
      setEntities(response.data.entities);
      setError(null); // Clear error if request was successful
      fetchPreviousNERs(); // Refresh previous NERs after new NER request
    } catch (error) {
      console.error("Error submitting NER request:", error);
      setError(error.message || "An error occurred during NER processing.");
    }
    setIsLoading(false);
  };

  const handleOpenModal = (ner) => {
    setSelectedNER({
      ...ner,
      persons: ner.persons ? ner.persons.split(",") : [],
      dates: ner.dates ? ner.dates.split(",") : [],
      organizations: ner.organizations ? ner.organizations.split(",") : [],
      locations: ner.locations ? ner.locations.split(",") : [],
    });
  };

  const handleCloseModal = () => {
    setSelectedNER(null);
  };

  const handleExportJson = (ner) => {
    const cleanNER = {
      original_text: ner.original_text,
      persons: ner.persons ? ner.persons.split(",") : [],
      dates: ner.dates ? ner.dates.split(",") : [],
      organizations: ner.organizations ? ner.organizations.split(",") : [],
      locations: ner.locations ? ner.locations.split(",") : [],
    };
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(cleanNER, null, 2)], {
      type: "application/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = `ner_${ner.id}.json`;
    document.body.appendChild(element);
    element.click();
  };

  const handleDelete = async (nerId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASIC_URL}ner/${nerId}`);
      fetchPreviousNERs();
    } catch (error) {
      console.error("Error deleting NER:", error);
    }
  };

  const isMostlyArabic = (text) => {
    const arabic = /[\u0600-\u06FF]/;
    let arabicLetters = 0;
    let otherLetters = 0;

    for (let char of text) {
      if (arabic.test(char)) arabicLetters++;
      else if (char.trim()) otherLetters++;
    }

    return arabicLetters > otherLetters;
  };

  const getTagColor = (tag) => {
    switch (tag.toUpperCase()) {
      case "PERSON":
        return "#007bff"; // Blue
      case "LOCATION":
        return "#28a745"; // Green
      case "DATE":
        return "#dc3545"; // Red
      case "ORGANIZATION":
        return "#ffc107"; // Yellow
      default:
        return "#6c757d"; // Gray
    }
  };

  const highlightEntities = (text, entities) => {
    if (!text || !entities.length) return text;

    const entityMap = entities.reduce((acc, entity) => {
      acc[entity.entity] = getTagColor(entity.tag);
      return acc;
    }, {});

    const regex = new RegExp(
      `(${entities.map((e) => e.entity).join("|")})`,
      "g"
    );
    const highlightedText = text.split(regex).map((part, index) =>
      entityMap[part] ? (
        <span
          key={index}
          style={{ color: entityMap[part], fontWeight: "bold" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );

    return (
      <div style={{ direction: isMostlyArabic(text) ? "rtl" : "ltr" }}>
        {highlightedText}
      </div>
    );
  };

  const ColorPanel = () => (
    <Box display="flex" justifyContent="center" mb={-2}>
      <Grid
        container
        spacing={2}
        justifyContent={language === "english" ? "left" : "right "}
      >
        <Grid item>
          <Box
            width={100}
            height={30}
            bgcolor="#007bff"
            borderRadius={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" style={{ color: "#ffffff" }}>
              {language === "english" ? "● Persons" : "● أشخاص "}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            width={100}
            height={30}
            bgcolor="#28a745"
            borderRadius={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" style={{ color: "#ffffff" }}>
              {language === "english" ? "● Locations" : "● أماكن "}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            width={100}
            height={30}
            bgcolor="#dc3545"
            borderRadius={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" style={{ color: "#ffffff" }}>
              {language === "english" ? "● Dates" : "● تواريخ "}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            width={130}
            height={30}
            bgcolor="#ffc107"
            borderRadius={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1" style={{ color: "#ffffff" }}>
              {language === "english" ? "● Organizations" : "● منظمات "}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{ maxWidth: "100%", p: 0.5, mt: 9, mb: -7 }}
    >
      <Box>
        <Typography
          variant="h5"
          sx={{ mb: 1, mt: -17, color: "#e3a40c", fontWeight: "bold" }}
        >
          {language === "english"
            ? "Named Entity Recognition"
            : "الكيانات المسماة"}
        </Typography>
      </Box>

      <Paper elevation={1} sx={{ p: 2, flexGrow: 1, mt: -12 }}>
        <TextField
          placeholder={language === "english" ? "Enter text" : "أدخل النص"}
          multiline
          rows={8}
          variant="outlined"
          fullWidth
          value={inputText}
          onChange={handleTextChange}
          sx={{ mb: 2, direction: isMostlyArabic(inputText) ? "rtl" : "ltr" }}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              bgcolor: "#c504ec",
              "&:hover": { backgroundColor: "#c504ec" },
            }}
            onClick={handleSubmit}
            disabled={!inputText || isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : language === "english" ? (
              "Submit"
            ) : (
              "إرسال"
            )}
          </Button>
        </Box>
      </Paper>
      <ColorPanel />

      <Paper
        elevation={3}
        sx={{
          p: 2,
          flexGrow: 1,
          mt: 2,
          maxHeight: 300,
          overflow: "auto",
          minHeight: 300,
          position: "relative",
        }}
      >
        {highlightEntities(outputText, entities)}
      </Paper>

      <Typography
        variant="h6"
        sx={{ color: "#e3a40c", fontWeight: "bold", mb: 1, mt: 3 }}
      >
        {language === "english"
          ? "Previous NER Results"
          : "نتائج الكيانات السابقة"}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {previousNERs.map((ner, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              p: 2,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            onClick={() => handleOpenModal(ner)}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {ner.original_text.substring(0, 50)}...
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  bgcolor: "#c504ec",
                  opacity: "70%",
                  "&:hover": { backgroundColor: "#9b03db" },
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(ner);
                }}
              >
                {language === "english" ? "View" : "عرض"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  bgcolor: "#c504ec",
                  opacity: "70%",
                  "&:hover": { backgroundColor: "#9b03db" },
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleExportJson(ner);
                }}
              >
                {language === "english" ? "Export as JSON" : "تصدير كملف JSON"}
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(ner.id);
                }}
              >
                {language === "english" ? "Delete" : "حذف"}
              </Button>
            </Box>
          </Paper>
        ))}
      </Box>

      <Dialog open={Boolean(selectedNER)} onClose={handleCloseModal}>
        <DialogTitle>
          {language === "english" ? "NER Details" : "تفاصيل الكيانات"}
        </DialogTitle>
        {selectedNER && (
          <DialogContent>
            <DialogContentText>
              <strong>
                {language === "english" ? "Original Text" : "النص الأصلي"}:
              </strong>
              <br />
              {selectedNER.original_text}
            </DialogContentText>
            <DialogContentText>
              <strong>{language === "english" ? "Persons" : "الأشخاص"}:</strong>
              <br />
              <ul>
                {selectedNER.persons.map((person, index) => (
                  <li key={index}>{person}</li>
                ))}
              </ul>
            </DialogContentText>
            <DialogContentText>
              <strong>{language === "english" ? "Dates" : "التواريخ"}:</strong>
              <br />
              <ul>
                {selectedNER.dates.map((date, index) => (
                  <li key={index}>{date}</li>
                ))}
              </ul>
            </DialogContentText>
            <DialogContentText>
              <strong>
                {language === "english" ? "Organizations" : "المنظمات"}:
              </strong>
              <br />
              <ul>
                {selectedNER.organizations.map((organization, index) => (
                  <li key={index}>{organization}</li>
                ))}
              </ul>
            </DialogContentText>
            <DialogContentText>
              <strong>
                {language === "english" ? "Locations" : "المواقع"}:
              </strong>
              <br />
              <ul>
                {selectedNER.locations.map((location, index) => (
                  <li key={index}>{location}</li>
                ))}
              </ul>
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {language === "english" ? "Close" : "إغلاق"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NamedEntityRecognitionComponent;
