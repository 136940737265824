import React, { useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Typography,
  Box,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { useLanguage } from "./LanguageContext";

const TranslationComponent = () => {
  const [inputText, setInputText] = useState("");
  const [Language, setLanguage] = useState("Arabic");
  const [outputText, setOutputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [previousTranslations, setPreviousTranslations] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState(null);
  const { language } = useLanguage();

  useEffect(() => {
    fetchPreviousTranslations();
  }, []);

  const fetchPreviousTranslations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASIC_URL}translations`
      );
      setPreviousTranslations(response.data.translations);
    } catch (error) {
      console.error("Error fetching previous translations:", error);
    }
  };

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      "ottoman-text": inputText,
      "target-language": Language,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASIC_URL}translation`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setOutputText(response.data["translated-text"]);
      fetchPreviousTranslations(); // Refresh previous translations after new translation
    } catch (error) {
      console.error("Error submitting translation request:", error);
    }
    setIsLoading(false);
  };

  // Function to count words
  const countWords = (text) => {
    return text.split(/\s+/).filter(Boolean).length;
  };

  const countSentences = (text) => {
    return text.split(/[.?!]+/).filter(Boolean).length;
  };

  const handleOpenModal = (translation) => {
    setSelectedTranslation(translation);
  };

  const handleCloseModal = () => {
    setSelectedTranslation(null);
  };

  const handleExportJson = (translation) => {
    const { id, ...data } = translation;

    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });
    element.href = URL.createObjectURL(file);
    element.download = `translation_${id}.json`;
    document.body.appendChild(element);
    element.click();
  };

  const handleDelete = async (translationId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASIC_URL}translations/${translationId}`);
      fetchPreviousTranslations(); // Refresh previous translations after deletion
    } catch (error) {
      console.error("Error deleting translation:", error);
    }
  };

  return (
    <Box sx={{ maxWidth: "100%", p: 2, mt: -12 }}>
      <Typography
        variant="h5"
        sx={{ mt: 3, mb: 2, color: "#e3a40c", fontWeight: "bold" }}
      >
        {language === "english" ? "Translation" : "ترجمة"}
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <TextField
              placeholder={language === "english" ? "Enter text " : "أدخل النص"}
              multiline
              rows={8}
              variant="outlined"
              fullWidth
              value={inputText}
              onChange={handleTextChange}
              sx={{ mb: 1 }}
            />

            <Typography
              variant="caption"
              display="block"
              sx={{ textAlign: "left", color: "#c504ec" }}
            >
              {language === "english" ? "words" : "كلمة"}:{" "}
              {countWords(inputText)} |
              {language === "english" ? "Sentences" : "جملة"}:{" "}
              {countSentences(inputText)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="language-select-label">
                {language === "english"
                  ? "Target Language"
                  : "اللغة المنشودة"}
              </InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                value={Language}
                label="Language"
                onChange={handleLanguageChange}
              >
                <MenuItem value="Arabic">
                  {language === "english" ? "Arabic" : "عربية"}
                </MenuItem>
                <MenuItem value="English">
                  {language === "english" ? "English" : "إنجليزية"}
                </MenuItem>
                <MenuItem value="Turkish">
                  {language === "english" ? "Turkish" : "تركية"}
                </MenuItem>
                <MenuItem value="French">
                  {language === "english" ? "French" : "فرنسية"}
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                bgcolor: "#c504ec",
                mt: 2,
                "&:hover": { backgroundColor: "#9b03db" },
              }}
              onClick={handleSubmit}
              disabled={!inputText}
            >
              {language === "english" ? "Translate" : "ترجمة "}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
        <TextField
          multiline
          rows={8}
          variant="outlined"
          fullWidth
          value={outputText} // Displaying the translated text
          InputProps={{
            readOnly: true,
          }}
          helperText={isLoading ? <CircularProgress size={24} /> : ""}
        />
        <Typography
          variant="caption"
          display="block"
          sx={{ textAlign: "left", color: "#c504ec" }}
        >
          {language === "english" ? "words" : "كلمة"}: {countWords(outputText)}{" "}
          |{language === "english" ? "Sentences" : "جملة"}:{" "}
          {countSentences(outputText)}
        </Typography>
      </Paper>
      <Typography
        variant="h6"
        sx={{ color: "#e3a40c", fontWeight: "bold", mb: 1, mt: 3 }}
      >
        {language === "english" ? "Previous Translations" : "الترجمات السابقة"}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {previousTranslations.map((translation, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              p: 2,
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
            onClick={() => handleOpenModal(translation)}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {translation.translated_text.substring(0, 50)}...
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {translation.target_language}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  bgcolor: "#c504ec",
                  opacity: "70%",
                  "&:hover": { backgroundColor: "#9b03db" },
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(translation);
                }}
              >
                {language === "english" ? "View" : "عرض"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  bgcolor: "#c504ec",
                  opacity: "70%",
                  "&:hover": { backgroundColor: "#9b03db" },
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleExportJson(translation);
                }}
              >
                {language === "english" ? "Export as JSON" : "تصدير كملف JSON"}
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{
                  textTransform: "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(translation.id);
                }}
              >
                {language === "english" ? "Delete" : "حذف"}
              </Button>
            </Box>
          </Paper>
        ))}
      </Box>

      {/* Modal for displaying translation details */}
      <Dialog open={Boolean(selectedTranslation)} onClose={handleCloseModal}>
        <DialogTitle>
          {language === "english" ? "Translation Details" : "تفاصيل الترجمة"}
        </DialogTitle>
        {selectedTranslation && (
          <DialogContent>
            <DialogContentText>
              <strong>
                {language === "english" ? "Original Text" : "النص الأصلي"}:
              </strong>
              <br />
              {selectedTranslation.ottoman_text}
            </DialogContentText>
            <DialogContentText>
              <strong>
                {language === "english" ? "Translated Text" : "النص المترجم"}:
              </strong>
              <br />
              {selectedTranslation.translated_text}
            </DialogContentText>
            <DialogContentText>
              <strong>{language === "english" ? "Language" : "اللغة"}:</strong>
              <br />
              {selectedTranslation.target_language}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            {language === "english" ? "Close" : "إغلاق"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TranslationComponent;
