import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import LoginPage from "./components/LoginPage";
import SignUpPage from "./components/SignUpPage";
import Dashboard from "./components/Dashboard";
import TranslationComponent from "./components/TranslationComponent";
import TextSummarisationComponent from "./components/TextSummarisationComponent";
import NamedEntityRecognitionComponent from "./components/NamedEntityRecognitionComponent";
import BookmarkingComponent from "./components/BookmarkingComponent";
import OCRComponent from "./components/OCRComponent";
import HomePage from "./components/HomePage/HomePage";
import Footer from "./components/Footer";
import MyAppBar from "./components/AppBar";
import { LanguageProvider } from "./components/LanguageContext";

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = payload.exp * 1000; // convert to milliseconds
    return Date.now() >= expirationTime;
  } catch (e) {
    return true;
  }
};

const App = () => {
  // Set initial state based on the presence and validity of the token
  const [loggedIn, setLoggedIn] = useState(() => {
    const token = localStorage.getItem("token");
    return token && !isTokenExpired(token);
  });

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("token");
      if (token && !isTokenExpired(token)) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
        localStorage.removeItem("token"); // Clear expired token
      }
    };

    checkToken();

    const intervalId = setInterval(checkToken, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Router>
      <LanguageProvider>
        <CssBaseline />
        <MyAppBar setLoggedIn={setLoggedIn} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            marginTop: "0px",
          }}
        >
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, paddingBottom: "80px" }}
          >
            <Routes>
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/" element={<HomePage />} />
              <Route
                path="/login"
                element={<LoginPage setLoggedIn={setLoggedIn} />}
              />
              <Route
                path="/dashboard/*"
                element={loggedIn ? <Dashboard /> : <Navigate to="/login" />}
              >
                <Route path="translation" element={<TranslationComponent />} />
                <Route
                  path="text-summarisation"
                  element={<TextSummarisationComponent />}
                />
                <Route
                  path="named-entity-recognition"
                  element={<NamedEntityRecognitionComponent />}
                />
                <Route path="bookmarking" element={<BookmarkingComponent />} />
                <Route path="ocr" element={<OCRComponent />} />
              </Route>
            </Routes>
          </Box>
          <Footer
            sx={{ mt: "auto", position: "fixed", bottom: 0, width: "100%" }}
          />
        </Box>
      </LanguageProvider>
    </Router>
  );
};

export default App;
