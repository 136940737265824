import React from "react";
import { Row, Container } from "react-bootstrap";
import bgpic from "./images/17107.png"; // Import the image file
import { useLanguage } from "../LanguageContext"; // Import the useLanguage hook

function Features() {
  const { language } = useLanguage(); // Get the current language from the language context

  return (
    <section id="features" dir={language === "arabic" ? "rtl" : "ltr"}>
      <div
        style={{
          direction: language === "arabic" ? "rtl" : "ltr",
          textAlign: language === "arabic" ? "right" : "left",
        }}
      >
        <Container className="features-section" style={{ marginTop: "60px" }}>
          <Row>
            <div className="section-image-features">
              <img
                src={bgpic}
                alt="Logo"
                style={{
                  marginLeft: 40,
                  marginRight: 40,
                  height: 300,
                  width: 300,
                }}
              />
            </div>
            <div className="section-text">
              <div className="section-text__title">
                {language === "english" ? "Features" : "الميزات"}
              </div>

              <div className="row">
                <div className="feature-box col-50">
                  <div className="section-text__title-small">
                    {language === "english"
                      ? "Language Processing Tools"
                      : "أدوات معالجة اللغة"}
                  </div>
                  <div className="feature-desc">
                    {language === "english"
                      ? "The webapp offers advanced tools for engaging with Ottoman texts, including translation and text summarization."
                      : "تقدم التطبيق عبر الويب أدوات متقدمة للتفاعل مع النصوص العثمانية، بما في ذلك الترجمة وتلخيص النصوص."}
                  </div>
                </div>

                <div className="feature-box col-50">
                  <div className="section-text__title-small">
                    {language === "english"
                      ? "Cultural Heritage Preservation"
                      : "الحفاظ على التراث الثقافي"}
                  </div>
                  <div className="feature-desc">
                    {language === "english"
                      ? "The webapp plays a vital role in preserving Ottoman cultural heritage through digital tools and resources."
                      : "يلعب التطبيق عبر الويب دورًا حيويًا في الحفاظ على التراث الثقافي العثماني من خلال الأدوات والموارد الرقمية."}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="feature-box col-50">
                  <div className="section-text__title-small">
                    {language === "english"
                      ? "Ottoman Document Analysis"
                      : "تحليل الوثائق العثمانية"}
                  </div>
                  <div className="feature-desc">
                    {language === "english"
                      ? "Dive deep into Ottoman texts with advanced analysis tools, unraveling their context and significance with precision."
                      : "انغمس في النصوص العثمانية باستخدام أدوات التحليل المتقدمة، مكشفًا عن سياقها وأهميتها بدقة."}
                  </div>
                </div>

                <div className="feature-box col-50">
                  <div className="section-text__title-small">
                    {language === "english"
                      ? "User-Friendly Interface"
                      : "واجهة المستخدم الودية"}
                  </div>
                  <div className="feature-desc">
                    {language === "english"
                      ? "Featuring a clean and intuitive interface, the webapp ensures effortless navigation for users of all levels."
                      : "تتميز بواجهة نظيفة وبديهية، يضمن التطبيق عبر الويب تصفحًا سلسًا للمستخدمين من جميع المستويات."}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Features;
